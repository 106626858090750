import cx from 'classnames'
import s from './styles.module.css'
import { CheckIcon } from 'components/design-system/kickoff-icons-v2/check-icon'
import ProductHeader from './product-header'

import { getPriceDetails } from './helpers'
import BulletPoints from '../bullet-points'
import CouponBadge from '../coupon-badge'
import { GROUP_TO_PRODUCT_TITLE } from 'components/payment/product-selection/product-selection-card'

interface IProgramCardProps {
  coupon: any
  billingPeriodInMonths: number
  baseProduct: any
  basePrice: any
  baseMonthlyPriceInDollars: number
  product: any
  price: any
  isFreeTrial: boolean
  coach: any
  showingLiveOptions?: boolean
  trialDays?: number
}

export default function ProgramCard({
  coupon,
  billingPeriodInMonths,
  baseProduct,
  basePrice,
  baseMonthlyPriceInDollars,
  product,
  price,
  isFreeTrial,
  trialDays,
  coach,
  showingLiveOptions,
}: IProgramCardProps) {
  const priceDetails = getPriceDetails({
    price,
    product,
    coupon,
    isFreeTrial,
    trialDays,
  })

  const showStrikeThrough =
    billingPeriodInMonths !== 1 || !!coupon || isFreeTrial

  return (
    <div className={cx(s.programCard, { [s.minHeight]: !showingLiveOptions })}>
      <div className={s.programCardHeader}>
        <div className={s.programOverline}>
          <span>Your Kickoff Program</span>
          {!!coupon && (
            <div className={s.programCouponBadge}>
              <CouponBadge coupon={coupon} />
            </div>
          )}
        </div>
        {showStrikeThrough && (
          <div className={s.programCardStrikeThrough}>
            <span>{priceDetails.baseDailyPriceInDollars}/day</span>
            <span>{priceDetails.priceWithoutMultiMonthDiscountInWords}</span>
          </div>
        )}

        <div className={s.programCardTitle}>
          <h3 className={s.programCardDailyPrice}>
            {priceDetails.dailyPriceInDollars}/day
          </h3>
          <span className={s.programCardDescription}>
            {priceDetails.priceInWords}
          </span>
        </div>
        {isFreeTrial && (
          <div className={s.programCardFreeTrialContainer}>
            <span className={s.programCardFreeTrialText}>
              {isFreeTrial
                ? `$${basePrice} onwards`
                : `$${priceDetails.priceInDollars} billed this month, $${basePrice} onwards`}
            </span>
          </div>
        )}
      </div>
      <div className={s.programCardBody}>
        <ProductHeader
          name={GROUP_TO_PRODUCT_TITLE[baseProduct.group] || baseProduct.name}
          billingPeriodInMonths={billingPeriodInMonths}
          monthlyPriceWithoutDiscount={basePrice}
          monthlyPrice={baseMonthlyPriceInDollars}
          coupon={coupon}
          isFreeTrial={isFreeTrial}
          trialDays={trialDays}
        />
        <BulletPoints
          productId={baseProduct.id}
          coachFirstName={coach?.user?.firstName}
        />
      </div>
      {product.callsPerWeek !== 0 && (
        <div className={s.programCardBody}>
          <ProductHeader
            name={GROUP_TO_PRODUCT_TITLE[product.group] || product.name}
            billingPeriodInMonths={billingPeriodInMonths}
            monthlyPriceWithoutDiscount={
              priceDetails.monthlyPriceWithoutMultiMonthDiscountInDollars -
              basePrice
            }
            monthlyPrice={
              priceDetails.monthlyPriceInDollars - baseMonthlyPriceInDollars
            }
            coupon={coupon}
            isFreeTrial={isFreeTrial}
            trialDays={trialDays}
          />
          <div>
            <div className={s.programDetailItem}>
              <div className={s.programCardCheckCircle}>
                <CheckIcon />
              </div>
              <span
                className={
                  s.programCardDetailText
                }>{`${product.callsPerWeek} Weekly Live Workout Guided by your Coach`}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
